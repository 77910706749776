import React, { FunctionComponent } from "react";
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";
import cx from "classnames";

import { ResourceCustomTheme } from "~/styles/config";

type StyleProps = {
  delayFn(mod: number): number;
};

const useStyles = makeStyles<ResourceCustomTheme, StyleProps>((_theme) => ({
  container: {
    width: "100%",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    marginBottom: 15,
  },
  defaultHeight: {
    height: 400,
  },
  circular: {
    animationDelay: ({ delayFn }) => `${delayFn(1400)}ms`,
  },
}));

type LoadingProps = {
  text?: string;
  inlineStyling?: boolean;
  style?: React.CSSProperties;
};

const Loading: FunctionComponent<LoadingProps> = ({
  text,
  inlineStyling = false,
  style,
}) => {
  const mountTime = React.useRef(Date.now());
  const mountDelayFn = (mod: number): number => -(mountTime.current % mod);

  const styles = useStyles({
    delayFn: mountDelayFn,
  });

  return (
    <div
      className={cx(styles.container, {
        [styles.defaultHeight]: !inlineStyling,
      })}
      style={style}
    >
      {text && (
        <Typography
          className={cx({
            [styles.text]: !inlineStyling,
          })}
          variant="body1"
        >
          {text}
        </Typography>
      )}
      <CircularProgress
        classes={{
          circleIndeterminate: styles.circular,
          indeterminate: styles.circular,
        }}
      />
    </div>
  );
};

export default Loading;
