import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import Color from "color";
import Alert from "@material-ui/lab/Alert";

import { ColorSpectrumFunc } from "./types";
import colorTineShadeHelperConstructor from "./mixins/colorTintShadeHelper";

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiAlert: typeof Alert;
  }
}

export const resourceMidnightBlue = "#1F1440";
export const resourceMidnightBlue200 = "#A5A1B3";

export const resourceRoyalPurple = "#7348FF";
export const resourceCrystalBlue = "#38E4FF";
export const resourceRed = "#FF2E57";
export const resourceGrey = "#EFEEF1";
export const resourceWhite = "#FFFFFF";
export const resourceOrange = "#FF7227";
export const resourceError = "#F33E22";
export const resourceSuccessGreen = "#28a745";
export const resourceNeutralGrey = "#79728C";

export const marineGreen = "#00DE9B";
export const brickRed = "#F33E22";
export const bananaYellow = "#FFD000";
export const pacificBlue = "#006AFF";

export const resourceLightGreyText = "#C4C4C4";

export const resourcePrimaryFont = "Circular";

type ThemeCreator<T> = (args: T) => ResourceCustomTheme;

type ThemeCreatorProps = {
  primaryColorHex: string;
  secondaryColorHex: string;
};

type JssStyles = Record<string, string>;

type CustomMixins = {
  moduleStyles: (theme: Theme) => JssStyles;
  containerStyles: (theme: Theme) => JssStyles;
  disabledStyles: JssStyles;
  placeholderStyles: (
    theme: ResourceCustomTheme,
    fontSize?: string
  ) => JssStyles | JssStyles;
};

type ResourceColors = {
  MidnightBlue: ColorSpectrumFunc;
  ElectricRed: ColorSpectrumFunc;
  RoyalPurple: ColorSpectrumFunc;
  FloridaOrange: ColorSpectrumFunc;
  CrystalBlue: ColorSpectrumFunc;
  KarlGray: ColorSpectrumFunc;
  MarineGreen: ColorSpectrumFunc;
  GreenhouseGreen: string;
  BananaYellow: string;
  PacificBlue: string;
  BrickRed: string;
  White: string;
  Black: string;
};

type CustomThemeOptions = {
  mixins: CustomMixins;
  colors: ResourceColors;
};

export type ResourceCustomTheme = Theme & CustomThemeOptions;

export const themeCreator: ThemeCreator<ThemeCreatorProps> = ({
  primaryColorHex,
  secondaryColorHex,
}) => {
  const generalHeaderStyles = {
    fontFamily: resourcePrimaryFont,
    fontStyle: "normal",
    color: resourceMidnightBlue,
    margin: "20px 0px 5px 0px",
  };

  const cardElevationStyles = {
    elevation1: {
      boxShadow: "none",
      border: "1px solid #E3E2E5",
      borderRadius: "4px",
    },
    elevation2: {
      boxShadow: "0px 4px 15px rgba(157, 157, 157, 0.25)",
      border: "1px solid #E3E2E5",
    },
  };

  const ResourceBaseTheme: ThemeOptions = {
    palette: {
      primary: {
        main: primaryColorHex,
      },
      secondary: {
        main: secondaryColorHex,
      },
      warning: {
        main: resourceOrange,
      },
      error: {
        main: resourceError,
      },
    },
    typography: {
      fontFamily: resourcePrimaryFont,
      h1: {
        ...generalHeaderStyles,
        fontWeight: "bold",
        fontSize: "64px",
        lineHeight: "68px",
        letterSpacing: "-1px",
      },
      h2: {
        ...generalHeaderStyles,
        fontWeight: "bold",
        fontSize: "52px",
        lineHeight: "58px",
        letterSpacing: "-0.8px",
      },
      h3: {
        ...generalHeaderStyles,
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "40px",
        letterSpacing: "-0.4px",
      },
      h4: {
        ...generalHeaderStyles,
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "28px",
        letterSpacing: "-0.2px",
      },
      h5: {
        ...generalHeaderStyles,
        fontWeight: 900,
        fontSize: "18px",
        lineHeight: "20px",
      },
      h6: {
        ...generalHeaderStyles,
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
      },
      body1: {
        fontFamily: resourcePrimaryFont,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
      },
      body2: {
        fontFamily: resourcePrimaryFont,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "36px",
      },
      // Body Small
      subtitle1: {
        fontFamily: resourcePrimaryFont,
        fontSize: "13px",
        lineHeight: "18px",
      },
      // Body Small ~ gray color
      subtitle2: {
        fontFamily: resourcePrimaryFont,
        fontSize: "13px",
        lineHeight: "18px",
        color: resourceLightGreyText,
      },
      caption: {
        fontFamily: "Circular",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "13px",
      },
    },
    props: {
      MuiTextField: {
        variant: "outlined",
      },
      MuiStepper: {
        alternativeLabel: true,
      },
      MuiStepButton: {
        disableRipple: true,
      },
      MuiPaper: {
        elevation: 2,
      },
      MuiCard: {
        elevation: 2,
      },
      MuiTabs: {
        indicatorColor: "primary",
        variant: "scrollable",
        scrollButtons: "on",
      },
      MuiTab: {
        disableRipple: true,
        wrapped: false,
      },
      MuiSwitch: {
        color: "primary",
      },
      MuiLink: {
        underline: "none",
        variant: "body1",
      },
    },
    // If you need to override a "lab" component, see this guide: https://github.com/mui-org/material-ui/issues/12164
    overrides: {
      MuiAlert: {
        root: {
          fontSize: "16px",
          fontFamily: resourcePrimaryFont,
          lineHeight: "24px",
          padding: "10px 24px",
        },
        filledSuccess: {
          backgroundColor: marineGreen,
        },
        filledInfo: {
          backgroundColor: pacificBlue,
        },
        filledWarning: {
          backgroundColor: bananaYellow,
        },
        filledError: {
          backgroundColor: brickRed,
        },
      },
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Circular",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
              src: `
            local('Circular'),
          `,
              unicodeRange:
                "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
            },
          ],
        },
      },
      MuiDialogActions: {
        root: {
          padding: 24,
        },
      },
      MuiTableHead: {
        root: {
          borderBottom: "1px solid #E9E7EC",
        },
      },
      MuiTableCell: {
        root: {
          borderBottomColor: "transparent",
          borderBottomWidth: "0px",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "16px",
        },
      },
      MuiTableRow: {
        root: {
          borderBottomColor: "transparent",
          borderBottomWidth: "0px",
          "&:not(:last-child)": {
            borderBottom: "1px solid #E9E7EC",
          },
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: "0.5rem",
          marginBottom: "0.2rem",
        },
      },
      MuiFab: {
        root: {
          boxShadow: "0px 4px 15px rgba(115, 72, 255, 0.4)",
        },
      },
      MuiButton: {
        root: {
          borderRadius: "22px",
          padding: "5px 15px",
          textTransform: "none",
        },
      },
      MuiChip: {
        outlined: {
          border: "1px solid rgba(0, 0, 0, 0.15)",
        },
        sizeSmall: {
          height: "16px",
          fontSize: "10px",
          borderRadius: "3px",
        },
        labelSmall: {
          paddingLeft: 6,
          paddingRight: 6,
          lineHeight: "16px",
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "#ffffff",
          color: "#5A5A5A",
          border: "1px solid #E2E2E2",
          borderRadius: "4px",
          padding: "8px",
          fontSize: "12px;",
          textAlign: "center",
        },
        tooltipPlacementBottom: {
          marginTop: "2px",
        },
      },
      // Expansion Panels
      MuiAccordion: {
        root: {
          transition: "box-shadow 500ms",
          ...cardElevationStyles.elevation1,
          "&:not(:last-child)": {
            marginBottom: "10px",
          },
          "&:before": {
            backgroundColor: "transparent",
          },
          "&$expanded": {
            margin: "10px 0",
            ...cardElevationStyles.elevation2,
          },
          "&$disabled": {
            backgroundColor: resourceWhite,
          },
        },
        rounded: {
          borderRadius: "4px",
        },
      },
      MuiAccordionSummary: {
        root: {
          "&$disabled": {
            opacity: 1,
            color: resourceMidnightBlue200,
          },
          "&.Mui-focused": {
            background: resourceWhite,
          },
        },
      },
      // Paper
      MuiPaper: {
        elevation1: {
          ...cardElevationStyles.elevation1,
        },
        elevation2: {
          ...cardElevationStyles.elevation2,
        },
      },
      MuiCardMedia: {
        root: {
          margin: "15px",
        },
      },
      // Icon Button
      MuiIconButton: {
        root: {
          color: resourceNeutralGrey,
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&$disabled": {
            color: Color(resourceNeutralGrey).lighten(0.7).hex().toString(),
          },
        },
      },
      // Stepper
      MuiStepper: {
        root: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },
      MuiStepConnector: {
        root: {
          backgroundColor: primaryColorHex,
          opacity: ".2",
        },
        alternativeLabel: {
          top: "5.5px",
          left: "calc(-50%)",
          right: "calc(50%)",
          position: "absolute",
          width: "100%",
          zIndex: 0,
        },
        active: {
          opacity: "1",
        },
        completed: {
          opacity: "1",
        },
        line: {
          height: 5,
          border: "0",
        },
        lineHorizontal: {
          borderTopWidth: "0",
        },
      },
      MuiTabs: {
        root: {
          paddingTop: "20px",
        },
        indicator: {
          height: "3px",
        },
        scrollButtons: {
          zIndex: 1000,
          "&:first-child": {
            marginRight: "-40px",
            background:
              "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%)",
            "&:empty": {
              width: "0px",
              marginRight: "0px",
            },
            "&:not(:empty)": {
              width: "40px",
              marginRight: "-40px",
            },
          },
          "&:last-child": {
            marginLeft: "-40px",
            background:
              "linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%)",
            "&:empty": {
              width: "0px",
              marginLeft: "0px",
            },
            "&:not(:empty)": {
              width: "40px",
              marginLeft: "-40px",
            },
          },
        },
      },
      MuiTab: {
        root: {
          textTransform: "none",
          fontFamily: resourcePrimaryFont,
          fontStyle: "normal",
          fontSize: "24px !important",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          transition: "background .5s ease-out, color .5s ease-out",
          color: "#1F1440",
          opacity: 1,
          fontWeight: 600,
          "&$textColorInherit": {
            opacity: 1,
          },
          "&$selected": {
            color: resourceRoyalPurple,
            background: "#F1EDFF",
          },
        },
      },
      MuiStepIcon: {
        root: {
          height: "16px",
          width: "16px",
          fill: primaryColorHex,
          opacity: ".2",
          boxSizing: "border-box",
        },
        active: {
          opacity: "1",
          border: `
          1px solid ${
            Color(primaryColorHex).isLight()
              ? Color(primaryColorHex).darken(0.2).hex().toString()
              : Color(primaryColorHex).darken(0.6).hex().toString()
          }
          `,
          borderRadius: "50%",
        },
        completed: {
          opacity: "1",
          background: primaryColorHex,
          borderRadius: "50%",
        },
        text: {
          color: "rgba(0, 0, 0, 0)",
          fill: "none",
        },
      },
      MuiStepLabel: {
        root: {
          fontFamily: resourcePrimaryFont,
          fontSize: "13px",
          lineHeight: "18px",
          textAlign: "center",
          "&$active": {
            fontWeight: "bold",
          },
          "&:hover $iconContainer": {
            boxShadow: `
            0px 1px 8px
  ${
    Color(primaryColorHex).isLight()
      ? `rgba(${Color(primaryColorHex)
          .darken(0.3)
          .rgb()
          .array()
          .join(", ")}, 0.5)`
      : `rgba(${Color(primaryColorHex).rgb().array().join(", ")}, 0.5)`
  }`,
          },
        },
        iconContainer: {
          background: "white",
          borderRadius: "50%",
          zIndex: 100,
        },
        label: {
          marginTop: "3px !important",
          fontFamily: resourcePrimaryFont,
          fontSize: "13px",
          lineHeight: "18px",
          color: resourceMidnightBlue,
          opacity: 0.5,
          "&:hover": {
            opacity: 1,
            fontWeight: "bold",
          },
          "&$active": {
            fontWeight: "bold !important",
          },
        },
        active: {
          opacity: 1,
        },
      },
      MuiSwitch: {
        root: {
          width: 35,
          height: 20,
          padding: 0,
          display: "flex",
          "&$checked": {
            "&$colorPrimary": {
              color: "#FFFFFF",
            },
          },
        },
        switchBase: {
          padding: 2,
          color: resourceNeutralGrey,
          "&$checked": {
            transform: "translateX(15px)",
            color: resourceWhite,
            "&$colorPrimary": {
              transform: "translateX(15px)",
              color: resourceWhite,
            },
            "& + $track": {
              opacity: 1,
              backgroundColor: primaryColorHex,
              borderColor: primaryColorHex,
            },
          },
        },
        thumb: {
          width: 16,
          height: 16,
          boxShadow: "none",
        },
        track: {
          border: `1px solid ${resourceNeutralGrey}`,
          borderRadius: 20 / 2,
          opacity: 1,
          backgroundColor: resourceWhite,
        },
      },
    },
  };

  const ResourceThemeCustomOptions: CustomThemeOptions = {
    mixins: {
      moduleStyles: (_theme: Theme) => ({
        marginBottom: "24px",
      }),
      containerStyles: (theme: Theme) => ({
        paddingLeft: "2%",
        paddingRight: "2%",
        maxWidth: "1600px",
        marginRight: "auto",
        marginLeft: "auto",
        marginBottom: "30px",
        [theme.breakpoints.up("sm")]: {
          paddingLeft: "5%",
          paddingRight: "5%",
        },
      }),
      disabledStyles: {
        opacity: "0.6",
      },
      placeholderStyles: (theme: ResourceCustomTheme, fontSize?: string) => ({
        fontFamily: "IBM Plex Mono, monospace",
        fontSize: fontSize ?? "0.9rem",
        fontWeight: "500",
        backgroundColor: theme.colors.RoyalPurple(100),
        color: theme.colors.RoyalPurple(700),
        borderRadius: "0.4rem",
        padding: "0.3rem 0.3rem",
        verticalAlign: "top",
      }),
    } as CustomMixins,
    colors: {
      MidnightBlue: colorTineShadeHelperConstructor(resourceMidnightBlue),
      ElectricRed: colorTineShadeHelperConstructor(resourceRed),
      RoyalPurple: colorTineShadeHelperConstructor(resourceRoyalPurple),
      FloridaOrange: colorTineShadeHelperConstructor(resourceOrange),
      CrystalBlue: colorTineShadeHelperConstructor(resourceCrystalBlue),
      MarineGreen: colorTineShadeHelperConstructor(marineGreen),
      KarlGray: colorTineShadeHelperConstructor(resourceGrey),
      GreenhouseGreen: "#27B3AB",
      BananaYellow: "#FFD000",
      PacificBlue: "#006AFF",
      BrickRed: "#F33E22",
      White: "#FFFFFF",
      Black: "#000000",
    },
  };

  return createMuiTheme(
    ResourceBaseTheme,
    ResourceThemeCustomOptions
  ) as ResourceCustomTheme;
};

export const resourceTheme = themeCreator({
  primaryColorHex: resourceRoyalPurple,
  secondaryColorHex: resourceRed,
});
