import { library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faEye,
  faEyeSlash,
  faChevronLeft,
  faPencil,
  faTimes,
  faCopy,
} from "@fortawesome/pro-light-svg-icons";

/**
 * This is our library of font awesome icons.
 *
 * Instructions:
 * Import the icon that you want to use then add it to the library below.
 *
 * Usage:
 * In your react component import `FontAwesomeIcon`. In the icon paramter,
 * pass in an array that contains the prefix and the icon name.
 *
 * Example:
 * ...
 * <FontAwesomeIcon icon={['fal', 'pencil']} />
 * ...
 *
 * prefix - fal
 * icon name - pencil
 *
 * Additional Info:
 * prefixes default to `fas` so for icon with that default prefix you can get
 * away without specificing the prefix.
 *
 * Example:
 * ...
 * <FontAwesomeIcon icon='user-circle' />
 * ...
 */
library.add(
  faChevronLeft,
  faLinkedin,
  faPencil,
  faEye,
  faEyeSlash,
  faTimes,
  faCopy
);
