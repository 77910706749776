import gql from "graphql-tag";

export default gql`
  query PrismaCurrentUser @hasura {
    currentUserPrisma {
      id
      analyticsUserId
      name: fullName
      firstName
      lastName
      canSendEmail
      onboardingComplete
      organization: currentOrganization {
        id
        name
        analyticsGroupId
        customer {
          id
          name
        }
      }
      isStaff
      roles
      primaryEmail
      title
      intercomHash
      createdAt
      auth0UserId
    }
  }
`;
