export type LocationState =
  | undefined
  | {
      referrer?: string;
    };

export const UUID_REGEX =
  "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";
export const SLUG_REGEX = "[a-zA-Z0-9-]+";
export const SHORT_ID_REGEX = "[a-zA-Z0-9]{8}";
