/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { User as Auth } from "~/utils/auth/useCurrentUser";
import InvalidPermissions from "./InvalidPermissions";

type UserProps = {
  auth?: Auth | null;
};

const StaffRoute: React.FC<UserProps & RouteProps> = ({ auth, ...rest }) => {
  if (!auth?.id) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: rest.location?.pathname },
        }}
      />
    );
  }
  if (!auth?.isStaff) {
    return <InvalidPermissions {...rest} />;
  }

  return <Route {...rest} />;
};

export default StaffRoute;
