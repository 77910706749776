import React, { FunctionComponent } from "react";
import cx from "classnames";
import useAnalytics from "~/react-hooks/useAnalytics";
import logo from "~/assets/images/logo.svg";

import styles from "./MadeWithLove.module.scss";

const getComposeMailLink = ({ firstName }: { firstName?: string }): string =>
  encodeURI(
    `mailto:?cc=beta@resource.io&subject=Interview guides product?&body=Hi -\n‎\nI was just sent an interview guide by another company and found it useful, so I thought I'd share it in case you might want to send interview guides as well. Here's some more info: https://candidate.guide\n‎\nThat's all - hope it's helpful :)${
      firstName ? `\n${firstName}` : ""
    }`
  );

interface MadeWithLoveProps {
  firstName?: string;
  showShare?: boolean;
}

const MadeWithLove: FunctionComponent<MadeWithLoveProps> = ({
  firstName,
  showShare = true,
}) => {
  const [analytics] = useAnalytics();
  return (
    <div className={styles.container}>
      <div className={styles.attribution}>
        <div className={styles.text}>Made with&nbsp;</div>
        <div className={styles.emoji}>
          <span role="img" aria-label="heart">
            ❤️
          </span>
        </div>
      </div>
      <a
        href="https://candidate.guide"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={cx(styles.attribution, styles.powered)}>
          <div className={styles.text}>Powered by &nbsp;</div>
          <img
            className={styles.logo}
            src={logo}
            alt="Guide logo"
            title="Guide logo"
          />
          <div className={styles.text}>&nbsp;Guide</div>
        </div>
      </a>
      {showShare && (
        <a
          href={getComposeMailLink({ firstName })}
          onClick={() => {
            analytics.track("Share From Guide Branding Clicked");
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.share}>Share with others</div>
        </a>
      )}
    </div>
  );
};
export default MadeWithLove;
