import gql from "graphql-tag";

const CURRENT_USER_HASURA = gql`
  query GetCurrentUserPrisma @hasura {
    currentUserPrisma {
      id
      nylasAccessTokenExists
      nylasMetadata
      fullName
      firstName
      lastName
      canSendEmail
      hasCalendarScope
      isStaff
      isSuperuser
      currentUserMembership {
        id
        roles
        hasLimitedAccess
      }
      currentOrganization {
        id
        name
        companyLogoUrl
        theme {
          primaryColor
          secondaryColor
        }
        customer {
          id
          name
          isGreenhouseIntegrated
          plan {
            id
            name
          }
        }
      }
      primaryEmail
      title
      intercomHash
      createdAt
      auth0UserId
      pitchPageOnboardingComplete
    }
  }
`;

export default CURRENT_USER_HASURA;
