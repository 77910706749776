import "regenerator-runtime/runtime";
import _ from "lodash";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { render } from "react-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  EmailAddresses,
  DisallowedDomains,
  ErrorCodes,
} from "@resource/common";
import { Router } from "react-router";
import { createBrowserHistory, History } from "history";
import queryString from "query-string";
import { setup } from "@resource/client-ffs";
import GuideLoading from "~/pages/Guide/GuideLoading";
import { PersistedRoleStateKey } from "~/pages/Login/Login";
import Loading from "~/components/Loading/Loading";
import { resourceTheme } from "~/styles/config";
import "~/styles/base.scss";
import * as serviceWorker from "./serviceWorker";
import setupAnalytics from "./setup-analytics";
import { AnalyticsContext } from "./analytics";
import { Analytics } from "./types";
import "./setup-fontAwesome.js";
import useIsGuideUrl from "~/react-hooks/useIsGuideUrl";
import "./setup-sentry";

import { Auth0Provider, AppState, useAuth0 } from "./react-auth0";
import AuthorizedApolloProvider from "./utils/auth/AuthorizedApolloProvider";

import { setFlashMessageContent } from "~/components/FlashMessage/FlashMessage";
import SYNC_USER_PRISMA from "./SYNC_USER_PRISMA@hasura";
import App from "./App";
import { HistoryContext } from "./HistoryContext";
import { SyncUserAuthPrisma } from "~/schemaTypesHasura";
import useAnalytics from "./react-hooks/useAnalytics";

momentDurationFormatSetup(moment);

// Check if we're in an iframe
if (window.location !== window.parent.location) {
  // eslint-disable-next-line no-underscore-dangle
  window._fs_is_outer_script = true;

  // Handle Nylas redirects
  const params = queryString.parse(window.location.search);
  const { fromScheduler } = params;
  if (fromScheduler === "true") {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
      {
        params: {
          ...params,
          href: window.location.href,
        },
      },
      window.location.origin
    );
    parent.postMessage(
      {
        params: {
          ...params,
          href: window.location.href,
        },
      },
      "https://app2.guide.co"
    );
  }
}

const AppWrapper: React.FC = () => {
  const analytics: Analytics = setupAnalytics();
  const { loading } = useAuth0();
  const isGuideUrl = useIsGuideUrl();
  return loading ? (
    <>{isGuideUrl ? <GuideLoading /> : <Loading />}</>
  ) : (
    <AnalyticsContext.Provider value={analytics}>
      <App />
    </AnalyticsContext.Provider>
  );
};

type AuthWrapperProps = {
  history: History;
};

const useCurrentGoogleToken = () => {
  const [analytics] = useAnalytics();
  const { getTokenSilently, user, isLogin } = useAuth0();

  const [storeAuth, { loading }] =
    useMutation<SyncUserAuthPrisma>(SYNC_USER_PRISMA);

  useEffect(() => {
    const createOrUpdateToken = async (): Promise<void> => {
      const token = await getTokenSilently();
      if (!token || !user?.sub) {
        return;
      }

      const customerDomain = EmailAddresses.parseOneAddress(user.email)?.domain;
      if (_.includes(DisallowedDomains, customerDomain)) {
        setFlashMessageContent({
          content: `Login with the ${customerDomain} domain is not allowed for Guide. Sign in with your G Suite account!`,
          severity: "error",
        });
        return;
      }

      let userRole = "User,Freemium";
      try {
        userRole = localStorage.getItem(PersistedRoleStateKey) ?? userRole;
      } catch (error) {
        console.error("Unable to retrieve role state key", error);
      }

      try {
        const res = await storeAuth({
          variables: {
            userRole,
          },
          refetchQueries: ["CurrentUser", "PrismaCurrentUser"],
        });
        const success = res.data?.syncUserAuthPrisma.success;
        if (_.isBoolean(success) && !success) {
          if (
            res.data?.syncUserAuthPrisma.code ===
            ErrorCodes.DOMAIN_DISALLOWED_ERROR
          ) {
            setFlashMessageContent({
              content: `${res.data.syncUserAuthPrisma.message} Please contact support for help!`,
              severity: "error",
            });
          } else {
            setFlashMessageContent({
              content:
                "Something went wrong with your login. Please contact support for help!",
              severity: "error",
            });
          }
          return;
        }

        const wasCreated = res.data?.syncUserAuthPrisma.created;
        if (_.isBoolean(wasCreated) && wasCreated) {
          if (userRole === "Freemium") {
            analytics.track("Freemium Signup Complete");

            try {
              localStorage.removeItem(PersistedRoleStateKey);
            } catch (error) {
              console.error("Unable to clear role state key", error);
            }
          } else {
            analytics.track("Signup Complete");
          }
        }
      } catch (error_) {
        Sentry.captureException(error_);
      }
    };

    // We only want to create or update the token on a post login
    if (isLogin) {
      createOrUpdateToken();
    }
    // User is the only value that can change that we really care about
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return loading;
};

const AuthWrapper: React.FC<AuthWrapperProps> = () => {
  const loading = useCurrentGoogleToken();

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={resourceTheme}>
        <CssBaseline />
        {loading ? <Loading /> : <AppWrapper />}
      </ThemeProvider>
    </StylesProvider>
  );
};

(async (): Promise<void> => {
  let FlagProvider: React.ComponentType<{ children: React.ReactNode }>;

  try {
    FlagProvider = await setup({
      clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY || "",
      user: {
        anonymous: true,
      },
    });
  } catch (error) {
    // Stuff has gone REALLY wrong
    Sentry.captureException(error);
    render(<h1>Something went wrong.</h1>, document.getElementById("root"));
    return;
  }
  // Create our own history so that we can access it outside of react context
  const history = createBrowserHistory();

  const onRedirectCallback = (appState: AppState): void => {
    const redirectPath = appState.referrer || "/";
    history.replace(redirectPath);
  };

  render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FlagProvider>
        <Auth0Provider
          onRedirectCallback={onRedirectCallback}
          domain={process.env.REACT_APP_AUTH0_TENANT_DOMAIN}
          client_id={process.env.REACT_APP_AUTH0_GUIDES_CLIENT_ID}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          redirect_uri={window.location.origin}
        >
          <AuthorizedApolloProvider>
            <HistoryContext.Provider value={history}>
              <Router history={history}>
                <AuthWrapper history={history} />
              </Router>
            </HistoryContext.Provider>
          </AuthorizedApolloProvider>
        </Auth0Provider>
      </FlagProvider>
    </MuiPickersUtilsProvider>,
    document.getElementById("root")
  );
})(); // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
