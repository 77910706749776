import { gql } from "@apollo/client";

const GetAvailableOrganizations = gql`
  query AvailableOrganizations @hasura {
    available_organizations {
      id
      name
    }
  }
`;

export default GetAvailableOrganizations;
