import { useEffect, useState } from "react";
import { ApolloError, useQuery, useReactiveVar } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useClient } from "@resource/client-ffs";
import { currentOrganizationId } from "~/cache";
import CURRENT_USER_PRISMA from "./CURRENT_USER_PRISMA@hasura";

import { useAuth0 } from "~/react-auth0";
import {
  GetCurrentUserPrisma,
  GetCurrentUserPrisma_currentUserPrisma as PrismaUser,
} from "~/schemaTypesHasura";

import { Analytics } from "~/types";

export type User = PrismaUser;

const segmentIntegrations = (hash: string | null) => ({
  integrations:
    // eslint-disable-next-line no-process-env
    process.env.NODE_ENV === "development"
      ? {
          All: false,
          FullStory: false,
          Intercom: false,
        }
      : {
          Intercom: {
            user_hash: hash,
          },
        },
});

type UseCurrentUserReturnType = {
  currentUser?: User | null;
  loading: boolean;
  error?: ApolloError;
};

export default (analytics: Analytics): UseCurrentUserReturnType => {
  const [launchDarklyLoading, setLaunchDarklyLoading] = useState(true);
  const { logout } = useAuth0();
  const flagsClient = useClient();
  const currentOrgId = useReactiveVar(currentOrganizationId);

  const { data, loading, error } =
    useQuery<GetCurrentUserPrisma>(CURRENT_USER_PRISMA);

  const user = data?.currentUserPrisma;

  useEffect(() => {
    if (user && !user.currentOrganization && currentOrgId) {
      // The user doesn't have permission to view the org we have
      // stored in our cache, so we should clear it and try again to get the
      // user's first available membership
      currentOrganizationId(null);
    }
  }, [user, currentOrgId]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  // Capture any LD Errors with Sentry
  useEffect(() => {
    const listener = (ldError: Error): void => {
      Sentry.captureException(ldError);
    };
    flagsClient.on("error", listener);
    return (): void => {
      flagsClient.off("error", listener);
    };
  }, [flagsClient]);

  useEffect(() => {
    if (user && user.currentOrganization) {
      if (!user.auth0UserId) {
        logout();
        return;
      }

      const { intercomHash, id: analyticsUserId } = user;
      const { fullName: name, primaryEmail, createdAt } = user;
      const { name: organizationName, id: organizationId } =
        user.currentOrganization || {};
      const customerPlanName = user.currentOrganization?.customer.plan.name;
      const analyticsGroupId = organizationId;

      analytics.identify(
        analyticsUserId,
        {
          id: user.id,
          name,
          email: primaryEmail,
          createdAt,
          customer: {
            id: analyticsGroupId,
            name: organizationName,
            plan: customerPlanName,
          },
        },
        segmentIntegrations(intercomHash)
      );

      if (analyticsGroupId && organizationName) {
        analytics.group(analyticsGroupId, {
          name: organizationName,
          type: "Company Name",
        });
      }

      if (organizationId && !currentOrganizationId()) {
        currentOrganizationId(organizationId);
      }

      if (user.primaryEmail) {
        Sentry.setUser({
          email: user.primaryEmail,
          id: user.id,
        });
      }
      flagsClient.identifyUser(
        {
          ...user,
          roles: user.currentUserMembership?.roles ?? [],
          currentOrganization: user.currentOrganization,
        },
        undefined,
        () => {
          setLaunchDarklyLoading(false);
        }
      );
    } else if (!loading) {
      setLaunchDarklyLoading(false);
    }
  }, [analytics, user, flagsClient, loading, logout]);

  return {
    currentUser: user,
    loading: loading || launchDarklyLoading,
    error,
  };
};
