import cx from "classnames";
import React, { FunctionComponent } from "react";
import { Button, Typography } from "@material-ui/core";

import styles from "./styles.module.scss";

interface InvalidPermissionsProps {
  history?: any | History;
}

const InvalidPermissions: FunctionComponent<InvalidPermissionsProps> = ({
  history,
}) => (
  <div className={cx(styles.container)}>
    <Typography variant="h4">
      Uh oh! It doesn&apos;t appear you have permission to view this page
    </Typography>
    <Button
      className={cx(styles.button)}
      variant="contained"
      color="primary"
      onClick={(): void => history.goBack()}
    >
      Go Back
    </Button>
  </div>
);

export default InvalidPermissions;
