import { useLocation, matchPath } from "react-router-dom";
import { UUID_REGEX, SLUG_REGEX, SHORT_ID_REGEX } from "~/routing";

export default () => {
  const location = useLocation();
  const isGuideUrl =
    !!matchPath(
      location.pathname,
      `/:customerSlug(${SLUG_REGEX})/:guideShortId(${SHORT_ID_REGEX})/:name`
    ) ||
    !!matchPath(location.pathname, `/guide/:guideId(${UUID_REGEX})`) ||
    !!matchPath(location.pathname, `/p/:pitchPageId(${UUID_REGEX})`);
  return isGuideUrl;
};
