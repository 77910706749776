import _ from "lodash";
import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { useQuery, useApolloClient } from "@apollo/client";
import { currentOrganizationId } from "~/cache";
import { AvailableOrganizations_available_organizations as Organization } from "~/schemaTypesHasura";
import GET_AVAILABLE_ORGANIZATIONS from "./GET_AVAILABLE_ORGANIZATIONS@hasura";

const LightAutocomplete = withStyles(() => ({
  input: {
    color: "white",
    underline: {
      color: "white",
    },
  },
  clearIndicatorDirty: {
    color: "white",
  },
  popupIndicator: {
    color: "white",
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
}))(Autocomplete);

type CompanySelectorProps = {
  light?: boolean;
  minimal?: boolean;
  className?: string;
};

const CompanySelector: React.FC<CompanySelectorProps> = ({
  light = true,
  minimal = false,
  className,
}) => {
  const client = useApolloClient();
  const currentId = currentOrganizationId();

  const { data } = useQuery(GET_AVAILABLE_ORGANIZATIONS);

  const availableOrganizations = data?.available_organizations || [];
  const organization = _.find(
    availableOrganizations,
    ({ id }) => id === currentId
  );

  const onChange = (e: React.ChangeEvent<unknown>, value: unknown): void => {
    const newOrganization = value as Organization;
    const organizationId = _.find(
      availableOrganizations,
      ({ name }) => name === newOrganization.name
    )?.id;
    if (organizationId && newOrganization?.name !== organization?.name) {
      currentOrganizationId(organizationId);
      try {
        localStorage.setItem(
          "__PersistedCurrentOrganizationIdStateKeyV2",
          organizationId
        );
      } catch (error) {
        console.info("Not writing persisted organization key");
        console.info(error);
      }
      client.resetStore();
      window.location.reload();
    }
  };

  if (!availableOrganizations || availableOrganizations?.length <= 1) {
    return null;
  }

  const AutoCompleteComponent = light ? LightAutocomplete : Autocomplete;

  return (
    <AutoCompleteComponent
      className={className}
      id="combo-box-demo"
      options={availableOrganizations}
      size="small"
      getOptionLabel={(option: unknown): string =>
        (option as Organization).name
      }
      getOptionSelected={(option: unknown, value: unknown) =>
        (option as Organization).id === (value as Organization).id
      }
      disableClearable
      openOnFocus
      value={organization as Organization}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          color="primary"
          variant={minimal ? "standard" : "outlined"}
          {...params}
        />
      )}
      onChange={onChange}
    />
  );
};

export default CompanySelector;
