import _ from "lodash";
import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { createGlobalState } from "react-hooks-global-state";
import * as Sentry from "@sentry/react";
import Slide from "~/components/SlideTransitionComponent/SlideTransitionComponent";

type GlobalState = {
  action: React.ReactNode | null;
  content: string | React.ReactNode;
  autoHideDuration: number;
  isOpen: boolean;
  onClose: () => Promise<void> | void;
  severity: Color;
};

const { getGlobalState, useGlobalState, setGlobalState } =
  createGlobalState<GlobalState>({
    action: null,
    content: "",
    autoHideDuration: 2000,
    isOpen: false,
    onClose: () => Promise.resolve(),
    severity: "info",
  });

export const setFlashMessageContent = ({
  content: newContent,
  severity: newSeverity = "info",
}: {
  content: string | React.ReactNode;
  severity: Color;
}) => {
  setGlobalState("content", newContent);
  setGlobalState("severity", newSeverity);
  setGlobalState("isOpen", true);
};

export const getCurrentState = () => {
  const content = getGlobalState("content");
  const isOpen = getGlobalState("isOpen");
  const onClose = getGlobalState("onClose");
  const action = getGlobalState("action");
  const autoHideDuration = getGlobalState("autoHideDuration");
  const severity = getGlobalState("severity");
  return {
    content,
    isOpen,
    action,
    onClose,
    autoHideDuration,
    severity,
  };
};

export const useFlashMessage = () => {
  const [, setContent] = useGlobalState("content");
  const [, setSeverity] = useGlobalState("severity");
  const [, setAutoHideDuration] = useGlobalState("autoHideDuration");
  const [, setIsOpen] = useGlobalState("isOpen");
  const [, setAction] = useGlobalState("action");
  const [, setOnClose] = useGlobalState("onClose");

  const handleSetContent = ({
    action,
    content: newContent,
    onClose,
    autoHideDuration,
    severity: newSeverity = "info",
  }: {
    action?: React.ReactNode;
    content: string | React.ReactNode;
    autoHideDuration?: number;
    onClose?: () => Promise<void> | void;
    severity?: Color;
  }) => {
    setContent(newContent);
    setSeverity(newSeverity);
    setIsOpen(true);
    if (autoHideDuration) {
      setAutoHideDuration(autoHideDuration);
    }
    if (action) {
      setAction(action);
    } else {
      setAction(null);
    }
    if (onClose) {
      setOnClose(onClose);
    } else {
      setOnClose(() => Promise.resolve());
    }
  };
  return {
    setContent: handleSetContent,
  };
};

type FlashMessageProps = Record<string, never>;

const FlashMessage: React.FC<FlashMessageProps> = () => {
  const [content, setContent] = useGlobalState("content");
  const [isOpen, setIsOpen] = useGlobalState("isOpen");
  const [onClose] = useGlobalState("onClose");
  const [action] = useGlobalState("action");
  const [severity] = useGlobalState("severity");
  const [autoHideDuration] = useGlobalState("autoHideDuration");

  const handleOnClose = async (): Promise<void> => {
    try {
      const finalOnClose = _.isFunction(onClose)
        ? onClose
        : () => Promise.resolve();
      await finalOnClose();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsOpen(false);
      // HACK: If we don't delay this, we'll accidentally flash an empty
      // content quickly
      setTimeout(() => setContent(""), 100);
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      autoHideDuration={autoHideDuration}
      onClose={handleOnClose}
      open={isOpen}
      TransitionComponent={Slide}
    >
      <Alert variant="filled" severity={severity} action={action}>
        {content}
      </Alert>
    </Snackbar>
  );
};

export { FlashMessage };
