import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "~/components/Skeleton";
import GuideLayout from "~/pages/GuideLayout";
import StageLoading from "./Stage/StageLoading";

type GuideLoadingProps = Record<string, unknown>;

const GuideLoading: React.FC<GuideLoadingProps> = () => (
  <GuideLayout trimColor="rgba(0, 0, 0, 0.11)">
    <Box
      paddingTop="16px"
      paddingBottom="16px"
      display="flex"
      justifyContent="center"
    >
      <Skeleton variant="circle" width={80} height={80} />
    </Box>
    <Box
      paddingTop="16px"
      paddingBottom="16px"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 280,
        margin: "0px auto 0px auto",
      }}
    >
      <Skeleton variant="circle" width="16px" height="16px" />
      <Skeleton variant="text" width="64px" height="12px" />
      <Skeleton variant="circle" width="16px" height="16px" />
      <Skeleton variant="text" width="64px" height="12px" />
      <Skeleton variant="circle" width="16px" height="16px" />
      <Skeleton variant="text" width="64px" height="12px" />
      <Skeleton variant="circle" width="16px" height="16px" />
    </Box>
    <StageLoading />
  </GuideLayout>
);

export default GuideLoading;
