import cx from "classnames";
import React, { FunctionComponent } from "react";
import { Button, Typography } from "@material-ui/core";

import styles from "./styles.module.scss";

interface NotFoundProps {
  history?: any | History;
}

const NotFound: FunctionComponent<NotFoundProps> = ({ history }) => (
  <div className={cx(styles.container)}>
    <Typography variant="h4">
      Uh oh! We couldn&apos;t find the page you are looking for
    </Typography>
    <Button
      className={cx(styles.button)}
      variant="contained"
      color="primary"
      onClick={(): void => history.push("/")}
    >
      Back Home
    </Button>
  </div>
);

export default NotFound;
