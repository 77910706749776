import React from "react";
import { makeStyles } from "@material-ui/core";
import { Skeleton, SkeletonProps } from "@material-ui/lab";
import { ResourceCustomTheme } from "~/styles/config";

type StyleProps = {
  delayFn(mod: number): number;
};

const useStyles = makeStyles<ResourceCustomTheme, StyleProps>((_theme) => ({
  pulse: {
    animationDelay: ({ delayFn }) => `${delayFn(1500)}ms`,
  },
  wave: {
    animationDelay: ({ delayFn }) => `${delayFn(1600)}ms`,
  },
}));

const SkeletonWrap: React.FC<SkeletonProps> = (props) => {
  const mountTime = React.useRef(Date.now());
  const mountDelayFn = (mod: number): number => -(mountTime.current % mod);

  const classes = useStyles({
    delayFn: mountDelayFn,
  });

  return (
    <Skeleton
      {...props}
      classes={{
        pulse: classes.pulse,
        wave: classes.wave,
      }}
    />
  );
};
export default SkeletonWrap;
