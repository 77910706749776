import React from "react";
import cx from "classnames";
import { faCircleNotch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import googleIcon from "~/icons/g-normal.svg";
import microsoftIcon from "~/icons/microsoft-normal.svg";
import styles from "./LoginButton.module.scss";
import { ProviderEnum } from "~/utils/auth";

const providerConfig = {
  google: {
    name: "google",
    text: "Sign in with Google",
    btnStyle: styles.googleBtn,
    iconStyle: styles.iconGoogle,
    icon: googleIcon,
  },
  outlook: {
    name: "outlook",
    text: "Sign in with Microsoft",
    btnStyle: styles.microsoftBtn,
    iconStyle: styles.iconMicrosoft,
    icon: microsoftIcon,
  },
};

type LoginButtonProps = {
  provider: ProviderEnum;
  loading: boolean;
  onLoginClick(provider: ProviderEnum): Promise<void>;
};

const LoginButton: React.FC<LoginButtonProps> = (props) => {
  const { provider, onLoginClick, loading } = props;

  return (
    <button
      type="button"
      className={cx(providerConfig[provider].btnStyle)}
      onClick={(): Promise<void> => onLoginClick(provider)}
      disabled={loading}
      name={providerConfig[provider].name}
      id={providerConfig[provider].name}
    >
      <img
        className={cx(providerConfig[provider].iconStyle)}
        src={providerConfig[provider].icon}
        alt={providerConfig[provider].name}
        title={providerConfig[provider].text}
      />
      <div className={cx(styles.content)}>
        {loading ? (
          <FontAwesomeIcon icon={faCircleNotch} size="2x" spin />
        ) : (
          <span className={cx(styles.buttonText)}>
            {providerConfig[provider].text}
          </span>
        )}
      </div>
    </button>
  );
};

export default LoginButton;
