/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";
import urljoin from "url-join";
import BasePathEnum from "~/enums/BasePathEnum";
import { User as Auth } from "~/utils/auth/useCurrentUser";
import { useFlashMessage } from "../FlashMessage/FlashMessage";

type UserProps = {
  auth?: Auth | null;
};

type FreemiumProps = {
  isFreemium?: boolean;
};

const PrivateRoute: React.FC<UserProps & RouteProps & FreemiumProps> = ({
  auth,
  isFreemium: isFreemiumRoute,
  ...rest
}) => {
  const { setContent } = useFlashMessage();
  const location = useLocation();

  if (!auth?.id) {
    return (
      <Redirect
        to={{
          pathname: "/login/",
          state: {
            referrer: rest.location
              ? urljoin(rest.location.pathname, rest.location.search)
              : undefined,
          },
        }}
      />
    );
  }

  const hasLimitedAccess = !!auth.currentUserMembership?.hasLimitedAccess;
  const isGreenhouseConfigured =
    !!auth.currentOrganization?.customer.isGreenhouseIntegrated;

  if (
    hasLimitedAccess &&
    !auth.pitchPageOnboardingComplete &&
    !location.pathname.startsWith(BasePathEnum.Onboarding)
  ) {
    return (
      <Redirect
        to={{
          pathname: BasePathEnum.Onboarding,
        }}
      />
    );
  }

  if (
    !isGreenhouseConfigured &&
    !hasLimitedAccess &&
    !location.pathname.startsWith("/settings/integrations")
  ) {
    setTimeout(() => {
      setContent({
        content: "A Greenhouse Harvest API Key is required to use Guide.",
        severity: "error",
      });
    }, 500);
    return (
      <Redirect
        to={{
          pathname: "/settings/integrations/",
        }}
      />
    );
  }

  return <Route {...rest} />;
};

export default PrivateRoute;
