import _ from "lodash";
import React, { FunctionComponent, ReactNode } from "react";
import cx from "classnames";
import {
  Badge,
  Card,
  Link,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {
  faHomeAlt,
  faCog,
  faGasPump,
  faSignOutAlt,
  faCommentAltLines,
  faLock,
  faCalendarAlt,
  faMagic,
} from "@fortawesome/pro-light-svg-icons";
import { faRoute, faUsers } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FeatureFlagEnum } from "@resource/common";
import { useFlags } from "@resource/client-ffs";

import BasePathEnum from "~/enums/BasePathEnum";
import TemplateIcon from "~/assets/icons/template-icon";
import logo from "~/assets/images/logo.svg";

import styles from "./Sidebar.module.scss";
import useAnalytics from "~/react-hooks/useAnalytics";
import { ResourceCustomTheme } from "~/styles/config";

import { useAuth0 } from "~/react-auth0";
import { User as CurrentUser } from "~/utils/auth/useCurrentUser";

type NavbarLink = {
  icon: ReactNode;
  url: string;
  text?: string;
  onClick?(): void;
  analyticsEvent: string;
  exact?: boolean;
  badgeContent?: string;
  hide: () => boolean;
};

type SidebarLinkProps = NavbarLink;

const useStyles = makeStyles<ResourceCustomTheme>((_theme) =>
  createStyles({
    root: {
      height: "3rem",
      "&:not(:last-child)": {
        marginBottom: "1.3rem",
      },
    },
    badge: {
      right: "1.5rem",
      height: "1rem",
      fontSize: "0.6rem",
      borderRadius: "0.2rem",
      padding: "0 0.3rem",
    },
  })
);

const SidebarLink: React.FC<SidebarLinkProps> = (props) => {
  const [analytics] = useAnalytics();
  const classes = useStyles();
  const {
    badgeContent,
    icon,
    url,
    text,
    exact = false,
    hide,
    onClick,
    analyticsEvent,
  } = props;

  if (hide()) {
    return null;
  }

  const SideLink = (
    <Link
      className={cx(styles.navItem, { [styles.noText]: !text }, classes.root)}
      activeClassName={styles.active}
      component={NavLink}
      exact={exact}
      to={url}
      onClick={(e: React.MouseEvent) => {
        if (analytics) {
          analytics.track(analyticsEvent);
        }
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      {icon}
      {text && (
        <Typography className={styles.text} variant="subtitle2">
          {text}
        </Typography>
      )}
    </Link>
  );

  if (badgeContent) {
    return (
      <Badge
        badgeContent={badgeContent}
        color="secondary"
        classes={{
          anchorOriginTopRightRectangle: classes.badge,
          root: classes.root,
        }}
      >
        {SideLink}
      </Badge>
    );
  }
  return SideLink;
};

type SidebarProps = {
  currentUser?: CurrentUser | null;
};

const Sidebar: FunctionComponent<SidebarProps> = ({ currentUser }) => {
  const [analytics] = useAnalytics();
  const { [_.camelCase(FeatureFlagEnum.PitchPages)]: pitchPagesEnabled } =
    useFlags();
  const auth0 = useAuth0();
  const hasLimitedAccess =
    !!currentUser?.currentUserMembership?.hasLimitedAccess;
  const isPitchPageOnboardingComplete =
    !!currentUser?.pitchPageOnboardingComplete;

  const navLinks: NavbarLink[] = [
    {
      icon: (
        <FontAwesomeIcon className={styles.icon} icon={faHomeAlt} fixedWidth />
      ),
      url: "/",
      exact: true,
      text: "Activity",
      analyticsEvent: "NavBar Button Guides Clicked",
      hide: (): boolean => hasLimitedAccess,
    },
    {
      icon: (
        <FontAwesomeIcon
          className={styles.icon}
          style={{ height: "32px", width: "32px" }}
          icon={faRoute}
          fixedWidth
        />
      ),
      url: hasLimitedAccess ? "/upsell" : "/journeys",
      text: "Journeys",
      analyticsEvent: "NavBar Button Journeys Clicked",
      hide: () => false,
    },
    {
      icon: <TemplateIcon className={styles.templatesIcon} />,
      url: "/templates",
      text: "Templates",
      analyticsEvent: "NavBar Button Templates Clicked",
      hide: (): boolean => hasLimitedAccess,
    },
    {
      icon: (
        <FontAwesomeIcon
          className={styles.icon}
          style={{ height: "32px", width: "32px" }}
          icon={faCalendarAlt}
          fixedWidth
        />
      ),
      url: `${BasePathEnum.Scheduler}/`,
      text: "Scheduler",
      analyticsEvent: "Scheduler Nav Clicked",
      hide: () => {
        if (auth0?.user?.sub.startsWith("waad")) {
          return true;
        }
        return false;
      },
    },
    {
      icon: (
        <FontAwesomeIcon
          className={styles.icon}
          style={{ height: "32px", width: "32px" }}
          icon={faUsers}
          fixedWidth
        />
      ),
      url: "/interviewers",
      text: "Team",
      analyticsEvent: "Interviewers In NavBar Clicked",
      hide: (): boolean => hasLimitedAccess,
    },
    {
      icon: (
        <FontAwesomeIcon
          className={styles.icon}
          icon={faCommentAltLines}
          fixedWidth
        />
      ),
      url: "/feedback",
      text: "Feedback",
      analyticsEvent: "NavBar Button Feedback Clicked",
      hide: (): boolean => hasLimitedAccess,
    },
    {
      icon: (
        <FontAwesomeIcon className={styles.icon} icon={faGasPump} fixedWidth />
      ),
      url: "/co2",
      text: "CO2",
      analyticsEvent: "CO2 NBav Clicked",
      hide: (): boolean => !currentUser?.isStaff,
    },
    {
      icon: (
        <FontAwesomeIcon className={styles.icon} icon={faLock} fixedWidth />
      ),
      url: "/admin",
      text: "Admin",
      analyticsEvent: "Admin Clicked",
      hide: (): boolean => !currentUser?.isStaff,
    },
    {
      icon: (
        <FontAwesomeIcon
          className={styles.icon}
          style={{ height: "32px", width: "32px" }}
          icon={faMagic}
          fixedWidth
        />
      ),
      url: isPitchPageOnboardingComplete
        ? `${BasePathEnum.PitchPages}/`
        : `${BasePathEnum.Onboarding}/${
            hasLimitedAccess ? "company" : "edit"
          }/`,
      text: "Pitch Pages",
      analyticsEvent: "Pitch Pages Nav Clicked",
      hide: (): boolean => !pitchPagesEnabled && !hasLimitedAccess,
      ...(!hasLimitedAccess ? { badgeContent: "NEW" } : {}),
    },
  ];

  const bottomNavLinks: NavbarLink[] = [
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faCog} fixedWidth />,
      url: "/settings",
      text: "Settings",
      analyticsEvent: "NavBar Button Company Clicked",
      hide: (): boolean => false,
    },
    ...(currentUser
      ? [
          {
            icon: (
              <FontAwesomeIcon
                className={styles.icon}
                icon={faSignOutAlt}
                fixedWidth
              />
            ),
            url: "/logout",
            text: "Sign Out",
            analyticsEvent: "NavBar Button Logout Clicked",
            async onClick() {
              const returnTo = `${
                window.location.origin
              }/login/?returnTo=${encodeURIComponent(
                window.location.pathname
              )}`;
              await auth0.logout({ returnTo });
            },
            hide: (): boolean => false,
          },
        ]
      : []),
  ];

  return (
    <Card className={styles.navbar}>
      <div className={styles.topNavSection}>
        <Link
          className={styles.navItem}
          component={NavLink}
          to="/"
          onClick={() => {
            if (analytics) {
              analytics.track("NavBar Button Resource Logo Clicked");
            }
          }}
        >
          <img
            className={styles.logo}
            src={logo}
            alt="Resource logo"
            title="Resource logo"
          />
        </Link>
        {navLinks.map((props, index) => (
          <SidebarLink {...props} key={index} />
        ))}
      </div>
      <div className={styles.bottomNavSection}>
        {bottomNavLinks.map((props, index) => (
          <SidebarLink {...props} key={index} />
        ))}
      </div>
    </Card>
  );
};

export default Sidebar;
