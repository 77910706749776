import React, { useCallback, useEffect } from "react";
import _ from "lodash";
import { Auth0Error } from "auth0-js";
import urljoin from "url-join";
import { Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

import { useQuery } from "@apollo/client";
import { useFlags } from "@resource/client-ffs";
import queryString from "query-string";
import { useFlashMessage } from "~/components/FlashMessage/FlashMessage";
import LoginButtonGroupGuidesAuth from "~/components/LoginButtonGroupGuidesAuth/LoginButtonGroupGuidesAuth";
import { ProviderEnum } from "~/utils/auth";
import logo from "~/assets/images/logos/guides.svg";
import emptyGuideImage from "~/assets/images/emptyGuideState.png";
import PRISMA_CURRENT_USER_QUERY from "~/queries/PRISMA_CURRENT_USER_QUERY@hasura";
import { LocationState } from "~/routing";
import inIframe from "~/utils/in-iframe";
import styles from "./Login.module.scss";
import { PrismaCurrentUser } from "~/schemaTypesHasura";
import useAnalytics from "~/react-hooks/useAnalytics";

type LoginProps = {
  selfServe?: boolean;
};

export const PersistedRoleStateKey = "PersistedRoleStateKey";

const Login: React.FC<LoginProps> = ({ selfServe }) => {
  const [analytics] = useAnalytics();

  const { data: currentUserPrisma } = useQuery<PrismaCurrentUser>(
    PRISMA_CURRENT_USER_QUERY
  );
  const hasCurrentUser = !!currentUserPrisma?.currentUserPrisma;
  const history = useHistory<LocationState>();
  const {
    pathname,
    state: { referrer } = { referrer: "" },
    search,
  } = useLocation<LocationState>();
  const { returnTo: returnToParam, f: freemiumParam } =
    queryString.parse(search);
  let returnTo: string | undefined;
  let isFreemium = !!selfServe;
  if (returnToParam) {
    returnTo = _.isArray(returnToParam) ? returnToParam[0] : returnToParam;
  }
  if (freemiumParam && !selfServe) {
    isFreemium =
      (_.isArray(freemiumParam) ? freemiumParam[0] : freemiumParam) === "true";
  }
  const { setContent } = useFlashMessage();

  useEffect(() => {
    if (isFreemium) {
      analytics.page("Freemium Login");
    } else {
      analytics.page("Login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If at any point we have a currentUser, redirect to the referrer
  useEffect(() => {
    if (hasCurrentUser) {
      if (!referrer || referrer === "/login") {
        history.push("/");
      } else {
        history.push(referrer);
      }
    } else if (returnTo) {
      history.push(returnTo);
    }
  }, [hasCurrentUser, history, referrer, returnTo]);

  useEffect(() => {
    if (isFreemium) {
      try {
        localStorage.setItem(PersistedRoleStateKey, "Freemium");
      } catch (error) {
        console.error("Unable to persist role state key", error);
      }
    }
  }, [isFreemium]);

  const onLogin = useCallback(
    (err?: Auth0Error): void => {
      if (err) {
        if (err.error_description === "customer.inactive") {
          setContent({
            content: err.error_description,
            severity: "error",
          });
        } else {
          setContent({
            content: "We could not login with your account.",
            severity: "error",
          });
        }
      } else if (inIframe()) {
        // If we're in an iframe, we'll be using loginWithPopup
        // and need to refresh the window post-login
        if (!referrer || referrer === "/login") {
          window.location.href = "/";
        } else {
          window.location.href = referrer;
        }
      }
    },
    [setContent, referrer]
  );

  const { redirectNewDashboard: redirectNewDashboardFlag } = useFlags();

  if (redirectNewDashboardFlag) {
    window.open(
      urljoin(process.env.REACT_APP_NEXTJS_APP_ROOT || "", pathname, search),
      "_self"
    );
    return null;
  }

  const providers = [ProviderEnum.Google, ProviderEnum.Outlook];

  return (
    <div className={styles.gridCont}>
      <header className={styles.header}>
        <img src={logo} alt="Resource logo" title="Resource logo" />
      </header>
      <div className={styles.sidebar}>
        <div className={styles.loginCont}>
          <Typography variant="h3">
            <span role="img" aria-label="Waving Hand">
              👋
            </span>{" "}
            Hey there
          </Typography>
          <Typography className={styles.bodyText} variant="body2">
            Welcome to Guide, we’re happy you’re here! To get started, just sign
            in{" "}
            <span role="img" aria-label="Hand Pointing Down">
              👇
            </span>
          </Typography>
          <LoginButtonGroupGuidesAuth
            onLogin={onLogin}
            providers={providers}
            usePopup={inIframe()}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.gettingStarted}>
          <img src={emptyGuideImage} alt="guide example" />
        </div>
      </div>
    </div>
  );
};

export default Login;
