import _ from "lodash";
import React, { Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import urljoin from "url-join";
import { FeatureFlagEnum, retry } from "@resource/common";
import { useFlags } from "@resource/client-ffs";

import useCurrentUser from "./utils/auth/useCurrentUser";
import useAnalytics from "~/react-hooks/useAnalytics";
import { FlashMessage } from "~/components/FlashMessage/FlashMessage";

import NotFound from "./components/common/NotFound";
import UnexpectedError from "~/components/common/UnexpectedError";
import PrivateRoute from "./components/common/PrivateRoute";
import StaffRoute from "./components/common/StaffRoute";
import Loading from "~/components/Loading/Loading";
import InvalidPermissions from "./components/common/InvalidPermissions";
import BasePathEnum from "./enums/BasePathEnum";
import CompanyDemo from "./pages/CompanyDemo/CompanyDemo";
import GuideLoading from "./pages/Guide/GuideLoading";
import Layout from "./pages/GuidesHomepage/Layout";
import useIsGuideUrl from "~/react-hooks/useIsGuideUrl";
import { UUID_REGEX, SLUG_REGEX, SHORT_ID_REGEX } from "./routing";

const GuideEditor = React.lazy(() =>
  retry(() => import("./pages/GuideEditor"))
);
const Upsell = React.lazy(() => retry(() => import("./pages/Upsell")));
const Guide = React.lazy(() => retry(() => import("./pages/Guide/Guide")));
const CookiesRequired = React.lazy(() =>
  retry(() => import("./pages/CookiesRequired"))
);
const Templates = React.lazy(() =>
  retry(() => import("./pages/GuidesHomepage/Templates/Templates"))
);
const Scheduler = React.lazy(() =>
  retry(() => import("./pages/GuidesHomepage/Scheduler/Scheduler"))
);
const Onboarding = React.lazy(() => retry(() => import("./pages/Onboarding")));
const PitchPage = React.lazy(() =>
  retry(() => import("./pages/PitchPage/PitchPage"))
);
const PitchPageTemplates = React.lazy(() =>
  retry(
    () => import("./pages/GuidesHomepage/PitchPageTemplates/PitchPageTemplates")
  )
);
const PitchPageTemplateRoot = React.lazy(() =>
  retry(
    () =>
      import("./pages/GuidesHomepage/PitchPageTemplate/PitchPageTemplateRoot")
  )
);
const SchedulerPage = React.lazy(() =>
  retry(() => import("./pages/SchedulerPage/SchedulerPage"))
);
const LegacySchedulerPage = React.lazy(() =>
  retry(() => import("./pages/SchedulerPage/LegacySchedulerPage"))
);
const Guides = React.lazy(() =>
  retry(() => import("./pages/GuidesHomepage/Guides/Guides"))
);
const SelfServe = React.lazy(() =>
  retry(() => import("./pages/Login/SelfServe"))
);
const Login = React.lazy(() => retry(() => import("./pages/Login/Login")));
const Activity = React.lazy(() =>
  retry(() => import("./pages/GuidesHomepage/Activity/Activity"))
);
const BookingConfirmed = React.lazy(() =>
  retry(() => import("./pages/BookingConfirmed/BookingConfirmed"))
);
const RoleJourneyEditor = React.lazy(() =>
  retry(
    () =>
      import(
        "./pages/GuidesHomepage/Templates/GuideTemplates/RoleJourneyEditor"
      )
  )
);
const RoleJourneys = React.lazy(() =>
  retry(
    () => import("./pages/GuidesHomepage/Templates/RoleJourneys/RoleJourneys")
  )
);
const Settings = React.lazy(() =>
  retry(() => import("./pages/GuidesHomepage/Settings/Settings"))
);
const CarbonDioxide = React.lazy(() =>
  retry(() => import("./pages/CarbonDioxide/CarbonDioxide"))
);
const Feedback = React.lazy(() =>
  retry(() => import("./pages/GuidesHomepage/Feedback/Feedback"))
);
const Admin = React.lazy(() => retry(() => import("./pages/Admin/Admin")));
const Interviewers = React.lazy(() =>
  retry(
    () =>
      import(
        "./pages/GuidesHomepage/Settings/Interviewers/InterviewersInSideBar"
      )
  )
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PropsType = Record<string, any>;

const PrivateLayoutRoute = (props: PropsType) => {
  const { pathname, search } = useLocation();
  const { redirectNewDashboard: redirectNewDashboardFlag } = useFlags();

  if (redirectNewDashboardFlag) {
    window.open(
      urljoin(process.env.REACT_APP_NEXTJS_APP_ROOT || "", pathname, search),
      "_self"
    );
    return null;
  }

  return (
    <Layout {...props}>
      <Suspense fallback={<Loading />}>
        <PrivateRoute {...props} />
      </Suspense>
    </Layout>
  );
};

const StaffLayoutRoute = (props: PropsType) => {
  const { pathname, search } = useLocation();
  const { redirectNewDashboard: redirectNewDashboardFlag } = useFlags();

  if (redirectNewDashboardFlag) {
    window.open(
      urljoin(process.env.REACT_APP_NEXTJS_APP_ROOT || "", pathname, search),
      "_self"
    );
    return null;
  }

  return (
    <Layout {...props}>
      <StaffRoute {...props} />
    </Layout>
  );
};

const DelayPrerenderRoute = (props: PropsType) => {
  window.prerenderReady = false;
  return <Route {...props} />;
};

const App: React.FC = () => {
  const [analytics] = useAnalytics();
  const {
    [_.camelCase(FeatureFlagEnum.CandidateActivity)]: candidateActivityFlag,
  } = useFlags();
  const isGuideUrl = useIsGuideUrl();
  const LoadingComponent = isGuideUrl ? <GuideLoading /> : <Loading />;
  const { currentUser, loading, error } = useCurrentUser(analytics);
  if (error) {
    return <UnexpectedError />;
  }

  if (loading) {
    return LoadingComponent;
  }

  return (
    <Suspense fallback={LoadingComponent}>
      <div>
        <FlashMessage />
      </div>
      <Switch>
        <Route
          path="/:url*"
          exact
          strict
          render={(props) => (
            <Redirect
              to={`${props.location.pathname}/${props.location.search}`}
            />
          )}
        />
        <Route
          path={`${BasePathEnum.LegacySchedulerPage}/:organizationId(${UUID_REGEX})/:pageSlug/:editHash?/:method?/`}
          component={LegacySchedulerPage}
        />
        <DelayPrerenderRoute
          path={`${BasePathEnum.SchedulerPage}/:pageSlug/:editHash?/:method?/`}
          component={SchedulerPage}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Home}
          exact
          component={candidateActivityFlag ? Activity : Guides}
        />
        <Route path="/demo/:onsiteId/:displayName" component={CompanyDemo} />
        <PrivateRoute
          auth={currentUser}
          path="/greenhouse-candidate/:candidateId(\d+)/application/:applicationId(\d+)"
          component={GuideEditor}
        />
        <Route path={`/p/:pitchPageId(${UUID_REGEX})/`} component={PitchPage} />
        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Templates}
          component={Templates}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={`/journeys/:uuid(${UUID_REGEX})/`}
          component={RoleJourneyEditor}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path="/journeys"
          component={RoleJourneys}
        />
        <PrivateLayoutRoute
          isFreemium
          auth={currentUser}
          path={BasePathEnum.Settings}
          component={Settings}
        />
        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Feedback}
          component={Feedback}
        />
        <StaffLayoutRoute
          auth={currentUser}
          path={BasePathEnum.CO2}
          exact
          component={CarbonDioxide}
        />
        <StaffLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Admin}
          exact
          component={Admin}
        />

        <PrivateLayoutRoute
          auth={currentUser}
          path={BasePathEnum.Interviewers}
          component={Interviewers}
          exact
        />
        <PrivateLayoutRoute
          isFreemium
          auth={currentUser}
          path={BasePathEnum.Scheduler}
          component={Scheduler}
        />
        <PrivateLayoutRoute
          isFreemium
          auth={currentUser}
          path={`${BasePathEnum.PitchPages}/:pitchPageTemplateId(${UUID_REGEX})`}
          component={PitchPageTemplateRoot}
        />
        <PrivateLayoutRoute
          isFreemium
          auth={currentUser}
          path={BasePathEnum.PitchPages}
          component={PitchPageTemplates}
        />

        <PrivateLayoutRoute
          isFreemium
          auth={currentUser}
          path={BasePathEnum.Upsell}
          component={Upsell}
        />
        <PrivateRoute
          isFreemium
          auth={currentUser}
          path={BasePathEnum.Onboarding}
          component={Onboarding}
        />
        <Redirect from="/company" to="/settings/company" />
        <Route path="/not-found" component={NotFound} />
        <Route path="/error" component={UnexpectedError} />
        <Route path="/invalid-permissions" component={InvalidPermissions} />
        <Route path="/login" component={Login} />
        <Route path="/enable-3rd-party-cookies" component={CookiesRequired} />
        <Route path="/signup" component={SelfServe} />
        <Route
          sensitive
          path={`/:customerSlug(${SLUG_REGEX})/:guideShortId(${SHORT_ID_REGEX})/:name`}
          component={Guide}
        />
        <Route path="/booking-confirmed" component={BookingConfirmed} />
        <Redirect from="*" to="/not-found" />
      </Switch>
    </Suspense>
  );
};

export default App;
