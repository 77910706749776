import { useEffect } from "react";

const RedirectLegacyComponent = () => {
  useEffect(() => {
    window.location.href = "https://candidate.guide";
  }, []);
  return null;
};

export default RedirectLegacyComponent;
