import * as Sentry from "@sentry/react";
import * as Integrations from "@sentry/integrations";

Sentry.init({
  ...(process.env.REACT_APP_SENTRY_DSN
    ? { dsn: process.env.REACT_APP_SENTRY_DSN }
    : {}),
  integrations: [new Integrations.ExtraErrorData()],
  environment: process.env.REACT_APP_APP_ENV,
  release: process.env.REACT_APP_COMMIT_REF,
  ignoreErrors: [
    "Non-Error exception captured with keys: error, error_description, state",
  ],
});
