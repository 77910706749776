import analytics from "analytics.js-loader";

let intializedAnalytics;
const getAnalyticsClient = () => {
  const apiKey = process.env.REACT_APP_SEGMENT_API_KEY;

  if (!intializedAnalytics) {
    if (apiKey && apiKey !== "null") {
      intializedAnalytics = analytics({
        writeKey: apiKey,
      });
    } else {
      console.warn(
        `analytics disabled because REACT_APP_SEGMENT_API_KEY is ${apiKey}`
      );
      intializedAnalytics = {
        track: (...args) => {
          console.debug("analytics.track:", ...args);
        },
        identify: (...args) => {
          console.debug("analytics.identify:", ...args);
        },
        group: (...args) => {
          console.debug("analytics.group:", ...args);
        },
        page: (...args) => {
          let [category, name, properties] = args;
          if (!properties) {
            properties = name || category;
            name = name ? category : undefined;
            category = undefined;
          }
          const data = {
            title: document.title,
            url: window.location.href,
            path: window.location.pathname,
            referrer: document.referrer,
            ...properties,
          };
          console.debug("analytics.page:", category, name, data);
        },
      };
    }
  }
  return intializedAnalytics;
};

export default getAnalyticsClient;
