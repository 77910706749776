import React, { FunctionComponent } from "react";
import OrganizationSwitcher from "~/components/OrganizationSwitcher/OrganizationSwitcher";
import Sidebar from "./Sidebar/Sidebar";
import styles from "./Layout.module.scss";
import { User as Auth } from "~/utils/auth/useCurrentUser";

type LayoutProps = {
  children: React.ReactNode;
  auth?: Auth | null;
};

const Layout: FunctionComponent<LayoutProps> = ({ auth, children }) => {
  return (
    <div className={styles.gridCont}>
      <div className={styles.sidebar}>
        <Sidebar currentUser={auth} />
      </div>
      <div className={styles.mainCont}>
        <OrganizationSwitcher
          className={styles.customerSwitcher}
          light={false}
        />
        <div className={styles.contentCont}>{children}</div>
        <footer className={styles.footer} />
      </div>
    </div>
  );
};
export default Layout;
