import { gql } from "@apollo/client";

const SYNC_USER_PRISMA = gql`
  mutation SyncUserAuthPrisma($userRole: String) @hasura {
    syncUserAuthPrisma(userRole: $userRole) {
      success
      message
      code
      user {
        id
        canSendEmail
        hasCalendarScope
      }
      created
    }
  }
`;

export default SYNC_USER_PRISMA;
