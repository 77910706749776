import _ from "lodash";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  useReactiveVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLError } from "graphql";
import { onError } from "@apollo/client/link/error";
import { hasDirectives, getMainDefinition } from "@apollo/client/utilities";
import { removeDirectivesFromDocument } from "apollo-utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import React from "react";
import * as Sentry from "@sentry/react";

import { createUploadLink } from "apollo-upload-client";

import { useAuth0 } from "~/react-auth0";
import {
  getCurrentState,
  setFlashMessageContent,
} from "~/components/FlashMessage/FlashMessage";
import { cache, currentOrganizationId, currentPitchPageId } from "~/cache";

// Test Auth User Data
//  jsonwebtoken.sign({
//      "sub": "google-oauth2|1234567890",
//      "name": "Test User",
//      "iat": 1516239022,
//      "iss": "https://resource-guide-development.us.auth0.com/",
//      "aud": ["https://guide-development-api.resource.io"],
//      "https://hasura.io/jwt/claims": {
//        "x-hasura-allowed-roles": ["user"],
//        "x-hasura-default-role": "user",
//        "x-hasura-user-id": "google-oauth2|1234567890",
//      }
//    }, '00000000000000000000000000000000');
const TEST_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJnb29nbGUtb2F1dGgyfDEyMzQ1Njc4OTAiLCJuYW1lIjoiVGVzdCBVc2VyIiwiaWF0IjoxNTE2MjM5MDIyLCJpc3MiOiJodHRwczovL3Jlc291cmNlLWd1aWRlLWRldmVsb3BtZW50LnVzLmF1dGgwLmNvbS8iLCJhdWQiOlsiaHR0cHM6Ly9ndWlkZS1kZXZlbG9wbWVudC1hcGkucmVzb3VyY2UuaW8iXSwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbInVzZXIiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoidXNlciIsIngtaGFzdXJhLXVzZXItaWQiOiJnb29nbGUtb2F1dGgyfDEyMzQ1Njc4OTAifX0.mexfoJTSD2D39JeeIeJ0IlQt__Xcswaq6g1kMAppNbc";

const useAuthToken = () => {
  const { isAuthenticated, getTokenSilently } = useAuth0();
  const getToken = (): Promise<string> | string | undefined => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    if (window.__use_test_token) {
      return TEST_TOKEN;
    }

    if (isAuthenticated) {
      return getTokenSilently();
    }
    return undefined;
  };
  return {
    getToken,
  };
};

const DEFAULT_MESSAGE =
  "Uh-oh. Something went wrong. Maybe try doing that again?";

// Set of operations we just ignore completely, and don't even show to users
// when error handling
const BLACKLISTED_OPERATIONS = ["GetLinkPreview"];
const BLACKLISTED_ERROR_CODES = ["NOT_FOUND"];
const SENTRY_BLACKLISTED_ERROR_CODES = ["UNAUTHENTICATED", "FORBIDDEN"];

const errorLink = onError(
  ({ response, operation, graphQLErrors, networkError }) => {
    if (BLACKLISTED_OPERATIONS.includes(operation.operationName)) {
      return;
    }

    // Just completely ignore some errors, like NOT_FOUND errors
    const filteredGraphQLErrors = _.reject(graphQLErrors, (graphQLError) => {
      return _.includes(
        BLACKLISTED_ERROR_CODES,
        graphQLError?.extensions?.code
      );
    });

    let message: string | undefined;
    if (filteredGraphQLErrors) {
      // Some errors we'll show to users, but not send to sentry, like auth stuff
      const sentryGraphQLErrors = _.reject(
        filteredGraphQLErrors,
        (graphQLError) => {
          return _.includes(
            SENTRY_BLACKLISTED_ERROR_CODES,
            graphQLError?.extensions?.code
          );
        }
      );
      Sentry.withScope((scope) => {
        scope.setTags({
          operationName: operation.operationName,
        });
        scope.setExtras({
          data: response?.data,
        });
        _.forEach(sentryGraphQLErrors, (error: GraphQLError) => {
          const finalError = _.isError(error)
            ? error
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              new Error(error.message!);
          _.assign(finalError, error);
          Sentry.captureException(finalError);
        });
      });
      message = _(filteredGraphQLErrors)
        .filter((error) => !!error.extensions)
        .map((error: GraphQLError) => {
          switch (error.extensions!.code) {
            case "GRAPHQL_VALIDATION_FAILED":
              return "Input was not valid";
            case "UNAUTHENTICATED":
              return "You have to be logged in to do that";
            case "FORBIDDEN":
              return "You don't have permission to do that";
            case "BAD_USER_INPUT":
              return "That input didn't seem to be valid";
            case "PASSTHROUGH":
              return error.message;
            default:
              return DEFAULT_MESSAGE;
          }
        })
        .join("\n");
    } else if (networkError) {
      Sentry.captureException(networkError);
      message = DEFAULT_MESSAGE;
    }
    const { content, isOpen, severity } = getCurrentState();
    // Only Show these errors if there isn't already an error message being shown
    if (
      message &&
      !(content && _.includes(message, content)) &&
      (!isOpen || severity !== "error")
    ) {
      setFlashMessageContent({
        content: message,
        severity: "error",
      });
    }
  }
);

const useHasuraHeaders = () => {
  const { getToken } = useAuthToken();
  const currentOrgId = useReactiveVar(currentOrganizationId);
  const pitchPageId = useReactiveVar(currentPitchPageId);

  const getHeaders = async () => {
    if (pitchPageId) {
      return {
        "x-hasura-pitch-page-id": pitchPageId,
        "x-hasura-role": "anonymous",
      };
    }
    const token = await getToken();
    return {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(currentOrgId ? { "x-current-organization-id": currentOrgId } : {}),
    };
  };
  return {
    getHeaders,
  };
};

type AuthorizedApolloProviderProps = {
  children: React.ReactElement;
};

const AuthorizedApolloProvider: React.FC<AuthorizedApolloProviderProps> = ({
  children,
}) => {
  const { getHeaders: getHasuraHeaders } = useHasuraHeaders();

  const getHeaderContext = async (
    existingHeaders?: Record<string, string>
  ) => ({
    headers: {
      ...existingHeaders,
      ...(await getHasuraHeaders()),
    },
  });

  const authLink = setContext(async (_, { headers }) => {
    return getHeaderContext(headers);
  });

  const uploadHttpLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_API_URL,
    credentials: "include",
  });

  const hasuraHttpLink = createUploadLink({
    uri: process.env.REACT_APP_HASURA_HTTP_URL,
    credentials: "include",
  });

  const websocketLink = new WebSocketLink({
    uri: process.env.REACT_APP_HASURA_WS_URL,
    options: {
      lazy: true,
      reconnect: true,
      timeout: 30000,
      connectionParams: getHeaderContext,
    },
  });

  const removeDirectiveLink = new ApolloLink((operation, forward) => {
    // eslint-disable-next-line no-param-reassign
    operation.query =
      removeDirectivesFromDocument([{ name: "hasura" }], operation.query) ??
      operation.query;
    return forward(operation);
  });

  const apolloClient = new ApolloClient({
    name: "42-web",
    version: process.env.REACT_APP_COMMIT_REF,
    link: ApolloLink.from([
      errorLink,
      authLink,
      ApolloLink.split(
        ({ query }) => hasDirectives(["hasura"], query),
        ApolloLink.from([
          removeDirectiveLink,
          ApolloLink.split(
            // split based on operation type
            ({ query }) => {
              const definition = getMainDefinition(query);
              const { kind, operation } = definition as {
                kind: string;
                operation?: string;
              };
              return (
                kind === "OperationDefinition" && operation === "subscription"
              );
            },
            websocketLink,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            hasuraHttpLink
          ),
        ]),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        uploadHttpLink
      ),
    ]),
    cache,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
