import React from "react";

import {
  Container,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "~/components/Loading/Loading";
import MadeWithLove from "~/components/MadeWithLove/MadeWithLove";
import { ResourceCustomTheme } from "~/styles/config";

type StyleProps = {
  trimColor?: string;
  showAlert: boolean;
};

const useStyles = makeStyles<ResourceCustomTheme, StyleProps>(
  (theme: ResourceCustomTheme) =>
    createStyles({
      trim: {
        backgroundColor: ({ trimColor }) => trimColor ?? "transparent",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          height: ({ trimColor }) => `${trimColor ? 11 : 5}rem`,
        },
        height: ({ trimColor, showAlert }) =>
          `${(trimColor ? 7 : 1) + (showAlert ? 4 : 0)}rem`,
      },
      preview: {
        position: "absolute",
        top: ({ showAlert }) => (showAlert ? 48 + 16 : 16),
        left: 16,
        padding: "4px 8px 4px 8px",
        fontSize: "12px",
        color: "white",
        borderRadius: 4,
        backgroundColor: "rgba(0,0,0,0.4)",
        fontWeight: "bold",
      },
      alert: {
        width: "100%",
        backgroundColor: "#E5F0FF",
        color: theme.colors.PacificBlue,
        padding: "14px 24px 14px 24px",
        minHeight: 48,
        display: "flex",
        alignItems: "center",
      },
      alertText: {
        color: "#006AFF",
        marginLeft: theme.spacing(1),
        fontSize: "14px",
      },
      alertLink: {
        textDecoration: "underline",
        color: theme.colors.FloridaOrange(),
        "&:hover": {
          textDecoration: "underline",
        },
      },
      guideWrap: {
        [theme.breakpoints.up("sm")]: {
          marginTop: ({ trimColor }) => `${trimColor ? -5 : 1.2}rem`,
        },
        marginTop: ({ trimColor }) => `${trimColor ? -3.2 : 0}rem`,
      },
      guideContainer: {
        [theme.breakpoints.up("sm")]: {
          border: "0.1rem solid #E3E2E5",
          borderRadius: "0.2rem",
          padding: "2rem 1.4rem 1.6rem",
        },
        backgroundColor: "white",
        marginBottom: "1.2rem",
        padding: `2rem ${theme.spacing(2)}px 1.6rem`,
        minHeight: "20rem",
      },
      madeWithLove: {
        margin: "1.2rem 0",
      },
    })
);

type GuideLayoutProps = {
  children: React.ReactNode;
  alert?: string;
  loading?: boolean;
  trimColor?: string;
  showShare?: boolean;
  preview?: boolean;
};

const GuideLayout: React.FC<GuideLayoutProps> = ({
  children,
  loading,
  trimColor,
  alert,
  showShare = true,
  preview,
}) => {
  const classes = useStyles({
    trimColor,
    showAlert: !!alert,
  });

  return (
    <div>
      {alert && (
        <div className={classes.alert}>
          <FontAwesomeIcon icon={faInfoCircle} size="1x" />
          <Typography variant="body1" className={classes.alertText}>
            {alert}
          </Typography>
        </div>
      )}
      <div className={classes.trim}>
        {preview && (
          <Typography className={classes.preview}>PREVIEW</Typography>
        )}
      </div>
      <Container className={classes.container} maxWidth="sm">
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.guideWrap}>
            <div className={classes.guideContainer}>{children}</div>
            <div className={classes.madeWithLove}>
              <MadeWithLove showShare={showShare} />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default GuideLayout;
