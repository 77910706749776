import cx from "classnames";
import React from "react";
import { Typography } from "@material-ui/core";

import styles from "./styles.module.scss";

const NotFound: React.FC = () => (
  <div className={cx(styles.container)}>
    <Typography variant="h4">
      Uh oh! Something unexpected happened - please contact support for more
      details.
    </Typography>
  </div>
);

export default NotFound;
