import { Auth0Error, WebAuth } from "auth0-js";
import urljoin from "url-join";

import { AuthConfig } from "./auth0-helpers";

export enum ProviderEnum {
  Google = "google",
  Outlook = "outlook",
}

type LoginCompletedCallback = (error?: Auth0Error | null) => void;

const DefaultGoogleAuth0Options = {
  connection: "google-oauth2",
  connectionScope:
    "https://www.googleapis.com/auth/gmail.compose, https://www.googleapis.com/auth/gmail.modify",
};

const DefaultOutlookAuth0Options = {
  connection: "idklabs-waad",
  connectionScope: "email",
};

const DefaultAuth0Options = {
  google: DefaultGoogleAuth0Options,
  outlook: DefaultOutlookAuth0Options,
};

export { login, logout } from "./auth0-helpers";

export const authConfigForProvider = (
  provider: ProviderEnum,
  onCompleted?: LoginCompletedCallback,
  returnToAfterLogout?: string
): AuthConfig => {
  const domain = process.env.REACT_APP_AUTH0_TENANT_DOMAIN!;
  const clientID = process.env.REACT_APP_AUTH0_GUIDES_CLIENT_ID!;
  const redirectUri = urljoin(process.env.REACT_APP_API_URL!, "auth0");
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const { connection, connectionScope } = DefaultAuth0Options[provider];
  const requestOfflineAccess = true;
  // eslint-disable-next-line no-param-reassign
  returnToAfterLogout =
    returnToAfterLogout || `${window.location.origin}/login`;
  const auth = new WebAuth({
    domain,
    clientID,
    responseType: "code",
    responseMode: "query",
    redirectUri,
    audience,
    scope: "openid profile email",
  });

  return {
    onCompleted,
    auth,
    usePopup: true,
    returnToAfterLogout,
    authOptions: {
      domain,
      clientId: clientID,
      redirectUri,
      audience,
      connection,
      responseType: "code",
      connection_scope: connectionScope,
      owp: true,
      // https://community.auth0.com/t/need-help-with-fetching-refresh-token-from-google-social-login/11699/5
      // https://auth0.com/rules/google-refresh-token
      // Enable if we don't get a refresh token
      ...(requestOfflineAccess
        ? {
            accessType: "offline",
            approvalPrompt: "force",
          }
        : {}),

      // DO NOT DO THIS - both will present an additional auth0 prompt
      // that is unnecessary
      // access_type: 'offline',
      // prompt: 'consent',
    },
    onError: (e) => {
      if (e.original === "User closed the popup window") {
        // we expect this to happen—it's /auth0?code=X returning a page that
        // does window.close()
        if (onCompleted) {
          onCompleted();
        }
      }
    },
  };
};
