import { useContext } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { Analytics } from "~/types";
import { AnalyticsContext } from "~/analytics";
import BasePathEnum from "~/enums/BasePathEnum";

const BasePathEnumRegex = _.mapValues(BasePathEnum, (path) => new RegExp(path));

const LocationsEnum = {
  Global: "global",
  Templates: "templates",
  Settings: "settings",
};

const projectLocation = (pathname: string): string => {
  if (pathname.match(BasePathEnumRegex.Settings)) {
    return LocationsEnum.Settings;
  }
  if (pathname.match(BasePathEnumRegex.Templates)) {
    return LocationsEnum.Templates;
  }
  return LocationsEnum.Global;
};

const useAnalyticsLocation = () => {
  // todo replace any with LocationState from ~/routing once James merges #8093
  const { pathname } = useLocation<{ pathname: string }>();
  const state = projectLocation(pathname);
  return [pathname, state];
};

type UseAnalytics = () => [Analytics];

const useAnalytics: UseAnalytics = () => {
  const analytics = useContext(AnalyticsContext);
  const [pathname, locationGroup] = useAnalyticsLocation();
  const globalAnalyticsContext = {
    pathname,
    locationGroup,
  };
  const analyticsWrapper: Analytics = {
    track: (event, config, options, callback) => {
      analytics.track(
        event,
        {
          ...config,
          ...globalAnalyticsContext,
        },
        options,
        callback
      );
      if (window.freshpaint) {
        window.freshpaint.track(
          event,
          {
            ...config,
            ...globalAnalyticsContext,
          },
          options,
          callback
        );
      }
    },
    identify: (id, traits, options, callback) => {
      analytics.identify(
        id,
        {
          ...traits,
          ...globalAnalyticsContext,
        },
        options,
        callback
      );
      if (window.freshpaint) {
        window.freshpaint.identify(
          id,
          {
            ...traits,
            ...globalAnalyticsContext,
          },
          options,
          callback
        );
      }
    },
    page: (name, properties, options, callback) => {
      analytics.page(
        name,
        {
          ...properties,
          ...globalAnalyticsContext,
        },
        options,
        callback
      );
      if (window.freshpaint) {
        window.freshpaint.page(
          name,
          {
            ...properties,
            ...globalAnalyticsContext,
          },
          options,
          callback
        );
      }
    },
    group: (event, config, options, callback) => {
      analytics.group(
        event,
        {
          ...config,
          ...globalAnalyticsContext,
        },
        options,
        callback
      );
      if (window.freshpaint) {
        window.freshpaint.group(
          event,
          {
            ...config,
            ...globalAnalyticsContext,
          },
          options,
          callback
        );
      }
    },
  };

  return [analyticsWrapper];
};

export default useAnalytics;
