import React from "react";
import { Paper, Box } from "@material-ui/core";
import Skeleton from "~/components/Skeleton";

type GuideLoadingProps = Record<string, unknown>;

const GuideLoading: React.FC<GuideLoadingProps> = () => (
  <>
    <Box paddingTop="16px" paddingBottom="16px">
      <Skeleton variant="text" width="85%" />
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="95%" />
    </Box>
    <Box paddingTop="16px" paddingBottom="16px">
      <Skeleton variant="rect" width="100%" height="240px" />
    </Box>
    <Box
      paddingTop="16px"
      paddingBottom="16px"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Paper variant="outlined" style={{ padding: 20, width: "48%" }}>
        <Box
          display="flex"
          style={{
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          <Skeleton
            variant="circle"
            width="40px"
            height="40px"
            style={{
              flexShrink: 0,
              marginRight: 12,
            }}
          />
          <div style={{ width: "100%" }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="100%" />
          </div>
        </Box>
        <Skeleton variant="rect" width="100%" height="120px" />
      </Paper>
      <Paper
        variant="outlined"
        style={{ padding: 20, width: "48%", marginLeft: "2%" }}
      >
        <Box
          display="flex"
          style={{
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          <Skeleton
            variant="circle"
            width="40px"
            height="40px"
            style={{
              flexShrink: 0,
              marginRight: 12,
            }}
          />
          <div style={{ width: "100%" }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="100%" />
          </div>
        </Box>
        <Skeleton variant="rect" width="100%" height="120px" />
      </Paper>
    </Box>
    <Box paddingTop="16px" paddingBottom="16px">
      <Skeleton variant="text" width="85%" />
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="95%" />
    </Box>
  </>
);

export default GuideLoading;
