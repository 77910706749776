import React from "react";

type TemplateIconProps = {
  className?: string;
};

const TemplateIcon: React.FunctionComponent<TemplateIconProps> = ({
  className,
}) => (
  <svg
    className={className}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1" fill="white">
      <rect width="9.625" height="10.8889" rx="1" />
    </mask>
    <rect
      width="9.625"
      height="10.8889"
      rx="1"
      fill="white"
      strokeWidth="3"
      mask="url(#path-1-inside-1)"
    />
    <mask id="path-2-inside-2" fill="white">
      <rect y="12.4445" width="9.625" height="8.55555" rx="1" />
    </mask>
    <rect
      y="12.4445"
      width="9.625"
      height="8.55555"
      rx="1"
      fill="white"
      strokeWidth="3"
      mask="url(#path-2-inside-2)"
    />
    <mask id="path-3-inside-3" fill="white">
      <rect x="11.375" width="9.625" height="8.55555" rx="1" />
    </mask>
    <rect
      x="11.375"
      width="9.625"
      height="8.55555"
      rx="1"
      fill="white"
      strokeWidth="3"
      mask="url(#path-3-inside-3)"
    />
    <mask id="path-4-inside-4" fill="white">
      <rect x="11.375" y="10.1111" width="9.625" height="10.8889" rx="1" />
    </mask>
    <rect
      x="11.375"
      y="10.1111"
      width="9.625"
      height="10.8889"
      rx="1"
      fill="white"
      strokeWidth="3"
      mask="url(#path-4-inside-4)"
    />
  </svg>
);

export default TemplateIcon;
