import { createContext, Context } from "react";
import { Analytics } from "~/types/Analytics";

export const defaultAnalytics: Analytics = {
  track: () => {
    console.log("analytics.track was used before it was defined");
  },
  identify: () => {
    console.log("analytics.identify was used before it was defined");
  },
  group: () => {
    console.log("analytics.group was used before it was defined");
  },
  page: () => {
    console.log("analytics.page was used before it was defined");
  },
};

export const fakeAnalytics: Analytics = {
  track: () => {},
  identify: () => {},
  group: () => {},
  page: () => {},
};

const AnalyticsContext: Context<Analytics> = createContext(defaultAnalytics);

export default AnalyticsContext;
