import Color from "color";

import { ColorSpectrumFunc } from "../types";

const colorTineShadeHelperConstructor = (
  baseColor: string
): ColorSpectrumFunc => {
  return (value): string => {
    switch (value) {
      case 100:
        return Color(baseColor).mix(Color("#fff"), 0.9).hex().toString();
        break;
      case 200:
        return Color(baseColor).mix(Color("#fff"), 0.6).hex().toString();
        break;
      case 300:
        return Color(baseColor).mix(Color("#fff"), 0.4).hex().toString();
        break;
      case 400:
        return Color(baseColor).mix(Color("#fff"), 0.2).hex().toString();
        break;
      case 500:
        return Color(baseColor).mix(Color("#fff"), 0.05).hex().toString();
        break;
      case 600:
        return Color(baseColor).mix(Color("#000"), 0.05).hex().toString();
        break;
      case 700:
        return Color(baseColor).mix(Color("#000"), 0.1).hex().toString();
        break;
      case 800:
        return Color(baseColor).mix(Color("#000"), 0.2).hex().toString();
        break;
      case 900:
        return Color(baseColor).mix(Color("#000"), 0.4).hex().toString();
        break;
      case 1000:
        return Color(baseColor).mix(Color("#000"), 0.6).hex().toString();
        break;
      default:
        return baseColor;
        break;
    }
  };
};

export default colorTineShadeHelperConstructor;
