import _ from "lodash";
import { FieldPolicy, Reference, makeVar } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { offsetLimitPagination as defaultOffsetLimitPagination } from "@apollo/client/utilities";

import introspectionQueryResultData from "./utils/auth/fragmentTypes.json";

const PersistedCurrentOrganizationIdStateKey =
  "__PersistedCurrentOrganizationIdStateKeyV2";

let initialCurrentOrganizationId: string | null = null;
try {
  initialCurrentOrganizationId = localStorage.getItem(
    PersistedCurrentOrganizationIdStateKey
  );
} catch (error) {
  console.info("Not reading persisted organization key");
  console.info(error);
  if (!window.location.pathname.includes("enable-3rd-party-cookies")) {
    window.location.pathname = "/enable-3rd-party-cookies";
  }
}
export const currentOrganizationId = makeVar<string | null>(
  initialCurrentOrganizationId || null
);

export const currentSchedulerPageSlug = makeVar<string | null>(null);

export const currentPitchPageId = makeVar<string | null>(null);

export const currentPitchPageTemplatesFilter = makeVar<string | null>(null);

window.addEventListener("storage", (event) => {
  const existingId = currentOrganizationId();
  if (
    event.key === PersistedCurrentOrganizationIdStateKey &&
    event.newValue !== existingId
  ) {
    currentOrganizationId(event.newValue);
    if (existingId) {
      window.location.reload();
    }
  }
});

/*
 * The following is ripped from the apollo source, as per there
 * recommendation when needing to customize pagination. We need
 * to because our default pagination wraps the args with an
 * input object.
 *
 * https://github.com/apollographql/apollo-client/blob/df7e5a52eeae7eebd12a47c5cd56b9ab12b11eff/src/utilities/policies/pagination.ts#L28
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyArgs = FieldPolicy<any>["keyArgs"];

function offsetLimitPagination<T = Reference>(
  keyArgs: KeyArgs = false
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged = existing ? existing.slice(0) : [];
      if (args?.input) {
        // Assume an offset of 0 if args.offset omitted.
        const {
          input: { offset = 0 },
        } = args;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < incoming.length; ++i) {
          merged[offset + i] = incoming[i];
        }
      } else {
        // It's unusual (probably a mistake) for a paginated field not
        // to receive any arguments, so you might prefer to throw an
        // exception here, instead of recovering by appending incoming
        // onto the existing array.
        merged.push(...incoming);
      }
      return merged;
    },
  };
}

export const cache = new InMemoryCache({
  possibleTypes: _.reduce(
    // eslint-disable-next-line no-underscore-dangle
    introspectionQueryResultData.__schema.types,
    (acc, type) => {
      acc[type.name] = _.map(type.possibleTypes, "name");
      return acc;
    },
    {}
  ),
  typePolicies: {
    Query: {
      fields: {
        currentOrganizationId: {
          read() {
            return currentOrganizationId();
          },
        },
        guide: defaultOffsetLimitPagination(["order_by"]),
      },
    },
    organization: {
      fields: {
        guides: {
          keyArgs: false,
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
        interviewers: defaultOffsetLimitPagination(["where"]),
      },
    },
    Organization: {
      fields: {
        interviewers: offsetLimitPagination((args) => {
          if (args?.input) {
            const {
              input: { filter, orderBy },
            } = args;
            return JSON.stringify(_.merge({}, filter, orderBy));
          }
          return undefined;
        }),
      },
    },
    Sender: {
      keyFields: ["email"],
    },
    atssync_greenhousejob: {
      keyFields: ["atssync_id"],
    },
    atssync_greenhousejobstages: {
      keyFields: ["atssync_id"],
    },
    atssync_greenhouseuser: {
      keyFields: ["atssync_id"],
    },
    atssync_greenhouseoffice: {
      keyFields: ["atssync_id"],
    },
    atssync_greenhousedepartment: {
      keyFields: ["atssync_id"],
    },
    atssync_greenhouseaccount: {
      keyFields: ["atssync_id"],
    },
  },
});
