import React from "react";
import * as Sentry from "@sentry/react";
import { Auth0Connection } from "@resource/common";
import { Auth0Error } from "auth0-js";
import { useLocation } from "react-router";
import { ProviderEnum } from "~/utils/auth";
import { useAuth0 } from "~/react-auth0";
import LoginButton from "../LoginButton/LoginButton";
import useAnalytics from "~/react-hooks/useAnalytics";
import { LocationState } from "~/routing";

export const Auth0AccessTokenStorageKey = "__ResourceAuth0AccessToken";

type LoginButtonProps = {
  provider: ProviderEnum;
  onLogin(err?: Auth0Error): void;
  usePopup: boolean;
};

const LoginButtonWithAuth: React.FC<LoginButtonProps> = (props) => {
  const { provider, onLogin, usePopup } = props;
  const { loginWithRedirect, loginWithPopup, loading, popupOpen } = useAuth0();
  const { state: { referrer } = { referrer: "" } } =
    useLocation<LocationState>();

  const [analytics] = useAnalytics();

  const onLoginClick = async (): Promise<void> => {
    analytics.track("Sign In Clicked", {
      provider,
    });
    try {
      const args = {
        appState: {
          referrer,
        },
        approval_prompt: "force",
        access_type: "offline",
        connection: Auth0Connection[provider],
        include_granted_scopes: true,
      };
      if (usePopup) {
        await loginWithPopup(args);
      } else {
        await loginWithRedirect(args);
      }
      onLogin();
    } catch (err) {
      Sentry.captureException(err);
      if (onLogin) {
        onLogin(err);
      }
    }
  };

  return (
    <LoginButton
      provider={provider}
      onLoginClick={onLoginClick}
      loading={loading || popupOpen}
    />
  );
};

type LoginButtonGroupProps = {
  providers: ProviderEnum[];
  onLogin(err: Auth0Error): void;
  className?: string;
  usePopup?: boolean;
};

const LoginButtonGroup: React.FC<LoginButtonGroupProps> = ({
  providers,
  onLogin,
  usePopup = false,
}) => {
  return (
    <>
      {providers.map((provider: ProviderEnum) => (
        <LoginButtonWithAuth
          key={provider}
          provider={provider}
          onLogin={onLogin}
          usePopup={usePopup}
        />
      ))}
    </>
  );
};

export default LoginButtonGroup;
